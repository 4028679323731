@import '../../utils/variables';

.container {
    position: relative;
    display: inline-flex;
    overflow: hidden;
}
.arrow {
    width: 1.5rem;
    height: 1.5rem;
}

//
//
//

@media screen and (max-width: $sp) {
    .arrow {
        width: 1.2rem;
        height: 1.2rem;
    }
}
