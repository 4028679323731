@import '../../utils/variables';

.container {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 7rem;
    padding: 0 2rem;
    background-color: $white;
    z-index: 5;
}
.wrapepr {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &::before {
        position: absolute;
        bottom: -1px;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #02120e;
        opacity: 0.1;
    }
}
.inner {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.spButton {
    display: none;
}
.main {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.logo {
    display: block;
    margin: 0 2.55rem 0 -1rem;
}
.logoSvg {
    width: 6rem;
    height: 6rem;
    padding: 1rem;
}
.navList {
    display: flex;
    flex-direction: row;
}
.navItemLink {
    display: inline-block;
    padding: 2.75rem 1.5rem;
    color: $key;
    font-size: 1.3rem;
    font-family: $gilroyExtra;
    font-weight: $bold;
    letter-spacing: 0.125rem;
}
.navItemLinkTop {
    display: none;
}
.navItemChild {
    display: none;
}
.navSub {
    display: none;
}
.navOrder {
    display: none;
}
.navBlank {
    display: none;
}
.navLogo {
    display: none;
}
.order {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 4rem;
    padding: 1.35rem 2.19rem;
    border-radius: 4rem;
}
.orderText {
    color: $white;
    font-family: $gilroySemi;
    font-weight: $semi;
    font-size: 1.1rem;
    letter-spacing: 0.115rem;
}
.orderTextParent {
    position: relative;
    display: inline-flex;
}
.orderTextParentInnerWord {
    display: inline-flex;
}
.orderTextParentInnerMark {
    display: inline-flex;
}
.orderTextWord {
    display: inline-block;
}

//
//
//

@media screen and (max-width: $sp) {
    .container {
        min-height: 6rem;
    }
    .wrapepr {
        justify-content: center;
        min-height: 6rem;

        &::before {
            left: -2rem;
            width: calc(100% + 4rem);
        }
    }
    .inner {
        justify-content: center;
        overflow: hidden;
    }
    .spButton {
        position: absolute;
        left: 0.75rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        z-index: 3;
    }
    .spButtonWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .spButtonBorder {
        position: absolute;
        width: 1.9rem;
        height: 2px;
    }
    .logo {
        margin: 0;
    }
    .logoSvg {
        width: 5.4rem;
        height: 5.4rem;
    }
    .nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        padding: 0 0 0 1rem;
        background-color: $key;
        overflow-x: hidden;
        overflow-y: scroll;
        z-index: 2;
    }
    .navList {
        flex-direction: column;
        width: 30rem;
        margin: 9.35rem auto 2.35rem;
    }
    .navItemLink {
        display: block;
        padding: 0.8rem 0;
        color: $white;
        font-size: 2.4rem;
        letter-spacing: 0.235rem;
    }
    .navItemLinkTop {
        display: block;
    }
    .navItemChild {
        display: block;
        margin: 0.1rem 0 0 2.85rem;
    }
    .navItemChildItemLink {
        display: block;
        padding: 0.95rem 0;
        color: $white;
        font-size: 1.3rem;
        font-family: $gilroyExtra;
        letter-spacing: 0.13rem;
    }
    .navOrder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30rem;
        min-height: 6rem;
        margin: 0 auto;
        padding: 2rem;
        background-color: $key-sub;
        border-radius: 3rem;
    }
    .navOrderText {
        color: $white;
        font-size: 1.3rem;
        font-family: $gilroySemi;
        letter-spacing: 0.135rem;
    }
    .navSub {
        position: relative;
        display: block;
        width: 30rem;
        margin: 3rem auto 1.35rem;
        padding: 0.9rem 0 0;
        &::before {
            position: absolute;
            top: 0;
            left: -2.8rem;
            content: '';
            width: 35.7rem;
            height: 1px;
            background-color: $white;
            opacity: 0.15;
        }
    }
    .navSubItem {
        position: relative;
        display: grid;
        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            background-color: #f9f8f3;
            opacity: 0.15;
        }
    }
    .navSubItemEn {
        .navSubItemTargetItemLink {
            padding: 0.725rem 0;
            font-family: $gilroySemi;
        }
    }
    .navSubItemTrigger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1.85rem 0;
    }
    .navSubItemTriggerText {
        position: relative;
        color: $white;
        font-size: 1.3rem;
        font-family: $gilroyExtra;
        letter-spacing: 0.135rem;
    }
    .navSubItemTriggerSvg {
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        margin: 0 0.45rem 0.1rem 0;
    }
    .navSubItemTriggerSvgBorder {
        position: absolute;
        top: calc(50% - 1px);
        path {
            width: 1rem;
            height: 1px;
        }
    }
    .navSubItemTargetList {
        margin: -1.35rem 0 0;
        padding: 0 0 1.85rem;
    }
    .navSubItemTargetItemLink {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.55rem 0;
        opacity: 0.5;
    }
    .navSubItemTargetItemText {
        margin: 0 0.5rem 0 0;
        color: $white;
        font-size: 1.3rem;
        letter-spacing: 0.065rem;
    }
    .navSubLink {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1.75rem 0;
        color: $white;
        font-size: 1.3rem;
        font-family: $gilroyExtra;
        letter-spacing: 0.065rem;
    }
    .navBlank {
        display: block;
        width: 30rem;
        margin: 0 auto 6.85rem;
    }
    .navBlankList {
        display: flex;
        flex-direction: row;
    }
    .navBlankLink {
        position: relative;
        // display: inline-block;
        display: inline-flex;
        align-items: center;
        padding: 0.55rem 0.5rem 0 0;
        margin: 0 2.8rem 0 0;
        color: $white;
        font-size: 1.1rem;
        font-family: $gilroySemi;
        letter-spacing: 0.115rem;
        opacity: 0.5;
    }
    .navBlankText {
        margin: 0 0.25rem 0 0;
    }
    // .navBlankLinkSvg {
    //     position: absolute;
    //     top: 0.65rem;
    //     right: -0.85rem;
    //     width: 1.2rem;
    //     height: 1.2rem;
    // }
    .navLogo {
        display: flex;
        justify-content: center;
        padding: 0 0 7.5rem;
    }
    .navLogoSvg {
        width: 30rem;
    }
    .order {
        position: absolute;
        right: -1.5rem;
        padding: 1.35rem;
    }
    .orderText {
        color: $key;
    }
    .orderTextParentInnerMark {
        width: auto;
    }
}
