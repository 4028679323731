@import '../../utils/variables';

.container {
    background-color: $key;
}
.info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 96rem;
    margin: 0 auto;
    padding: 8.75rem 0 10rem;
}
.infoNews {
    width: 42rem;
    .infoSectionText {
        width: 37rem;
        margin: 0 0 5rem;
    }
}
.infoApp {
    position: relative;
    width: 48rem;
    .infoSectionText {
        width: 29rem;
        margin: 0 0 2.4rem;
    }
}
.infoSectionTitle {
    margin: 0 0 3.05rem;
    color: $white;
    font-size: 2.4rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.24rem;
}
.infoSectionText {
    color: $white;
    font-size: 1.3rem;
    line-height: 2;
    letter-spacing: 0.1rem;
}
.infoSectionMail {
    display: flex;
    flex-direction: row;
    height: 6rem;
}
.infoSectionMailLabel {
    position: absolute;
    visibility: hidden;
}
.infoSectionMailHiddenInput {
    position: absolute;
    visibility: hidden;
}
.infoSectionMailInput {
    width: 81.25%;
    height: 6rem;
    padding: 0rem 4rem;
    background-color: $white;
    color: $key;
    font-size: 1.3rem;
    font-family: $gilroySemi;
    border-radius: 3rem 0 0 3rem;
    letter-spacing: 0.125rem;
    &::placeholder {
        color: $key;
        opacity: 0.5;
    }
}
.infoSectionMailButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18.75%;
    height: 6rem;
    background-color: $key_sub;
    border-radius: 0 3rem 3rem 0;
}
.infoSectionButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 25rem;
    min-height: 6rem;
    padding: 2.15rem;
    background-color: $key_sub;
    border-radius: 4rem;
    letter-spacing: 0.125rem;
}
.infoSectionButtonText {
    color: $white;
    font-size: 1.3rem;
    font-family: $gilroyExtra;
}
.infoSectionMailButtonSubscribe {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0 3rem 3rem 0;
    opacity: 0;
}
.infoSectionMailButtonSvg {
    width: 1.2rem;
    height: 1.2rem;
}
.infoSectionImg {
    position: absolute;
    top: -11.2rem;
    right: -2.2rem;
    background-color: $white;
    border-radius: 1.55rem;
    box-shadow: 0px 2rem 3rem rgba(0, 0, 0, 0.2);
}
.infoSectionImgLink {
    position: relative;
    display: block;
    width: calc(18rem + 4px);
    height: calc(38.9rem + 4px);
    border: 2px solid $white;
    border-radius: 1.55rem;
    overflow: hidden;
}
.infoSectionImgInside {
    position: absolute;
    top: 0;
    left: 0;
    width: 18rem;
    height: calc(38.9rem + 1px);
    filter: drop-shadow(0px 0px rgba(0, 0, 0, 0));
    outline: none;
    border: none;
    border-radius: 1rem;
}
.main {
    position: relative;
    width: 156rem;
    margin: 0 auto;
    padding: 8.25rem 30rem 8.65rem;
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $white;
        opacity: 0.15;
    }
}
.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 3.25rem;
}
.navList {
    display: flex;
    flex-direction: row;
}
.navItem {
    width: 24rem;
    &:nth-child(3) {
        width: 16rem;
        .navItemTargetItemLink {
            padding: 0.725rem 0;
        }
        .navItemTargetItemText {
            font-family: $gilroySemi;
            letter-spacing: 0.13rem;
        }
        &:last-child {
            margin: -0.25rem 0 0;
        }
    }
    &:nth-child(4) {
        width: 16rem;
        .navItemTargetItemLink {
            padding: 0.725rem 0;
        }
        .navItemTargetItemText {
            font-family: $gilroySemi;
            letter-spacing: 0.13rem;
        }
        &:last-child {
            margin: -0.25rem 0 0;
        }
    }
}
.navItemSm {
    width: 16rem;
    .navItemTargetItemLink {
        padding: 0.725rem 0;
    }
    .navItemTargetItemText {
        font-family: $gilroySemi;
        letter-spacing: 0.13rem;
    }
    &:last-child {
        margin: -0.25rem 0 0;
    }
}
.navLink {
    position: relative;
    display: block;
    margin: 0 0 2.05rem;
    padding: 0.55rem 0;
    color: $white;
    font-size: 1.3rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.125rem;
}
.navItemTrigger {
    display: none;
}
.navItemTriggerText {
    position: relative;
    color: $white;
    font-size: 1.3rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.115rem;
}
.navItemTargetLink {
    display: flex;
    align-items: center;
    margin: 0 0.5rem 0 0;
    padding: 0.55rem 0;
    color: $white;
    font-family: $gilroyExtra;
    font-size: 1.3rem;
    letter-spacing: 0.125rem;
}
.navItemTargetItemMain {
    margin: 0 0 -0.15rem;
    .navItemTargetItemText {
        font-family: $gilroyExtra;
        letter-spacing: 0.125rem;
    }
}
.navItemTargetItemLink {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.55rem 0;
}
.navItemTargetItemText {
    margin: 0 0.5rem 0 0;
    color: $white;
    font-size: 1.3rem;
    letter-spacing: 0.065rem;
}
.navChildItemApp {
    margin: 0 0 2.2rem;
}
.navBlankLinkArrow {
    position: relative;
    display: inline-flex;
    overflow: hidden;
}
.navBlankLinkSvg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0px 0 -0.25rem 0.5rem;
}
.navSns {
    width: 16rem;
    margin: 0 -0.5rem 0 0px;
}
.navSnsList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.navSnsLink {
    display: block;
    padding: 0.55rem 1.1rem;
}
.navSnsSvgInstagram {
    width: 1.8rem;
    height: 1.8rem;
}
.navSnsSvgTwitter {
    width: 2rem;
    height: 1.7rem;
}
.navSnsSvgFacebook {
    width: 1.9rem;
    height: 1.8rem;
}
.navSnsSvgNote {
    width: 1.5rem;
    height: 1.6rem;
}
.navSub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.navSubList {
    display: flex;
    flex-direction: row;
}
.navSubItem {
    margin: 0 2.75rem 0 0;
}
.navSubItemLink {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0.55rem 0;
    .navBlankLinkSvg {
        top: 0.55rem;
        right: -1.75rem;
    }
}
.navSubItemText {
    margin: 0 0.5rem 0 0;
    color: $white;
    font-size: 1.3rem;
    font-family: $gilroySemi;
    letter-spacing: 0.135rem;
}
.navSubSmall {
    margin: 0.55rem 0 0;
    color: $white;
    font-size: 1.3rem;
    font-family: $gilroyExtra;
    letter-spacing: 0.115rem;
}
.logo {
    display: flex;
    justify-content: center;
    padding: 0 0 4rem;
}
.logoSvg {
    width: 156rem;
}

//
//
//

@media screen and (min-width: $pc) {
    .main {
        &::before {
            left: calc(-50vw + 50% + 2rem);
            width: calc(100vw - 4rem);
        }
    }
    .logoSvg {
        width: 97.5vw;
    }
}

//
//
//

@media screen and (max-width: $sp) {
    .info {
        flex-direction: column;
        width: 30rem;
        padding: 7rem 0 5rem;
    }
    .infoNews {
        width: 100%;
        margin: 0 0 8rem;
        .infoSectionText {
            width: 100%;
            margin: 0 0 2.55rem;
        }
    }
    .infoApp {
        width: 100%;
        .infoSectionTitle {
            width: 18.5rem;
        }
        .infoSectionText {
            width: 18.5rem;
            margin: 0 0 5.55rem;
            letter-spacing: 0.085rem;
        }
    }
    .infoSectionTitle {
        margin: 0 0 0.9rem;
        line-height: 1.25;
        letter-spacing: 0.255rem;
    }
    .infoSectionText {
        font-size: 1.1rem;
        line-height: 1.7;
        letter-spacing: 0.055rem;
    }
    .infoSectionMail {
        width: 30.85rem;
    }
    .infoSectionMailInput {
        width: 80.5%;
        padding: 0 3rem;
        letter-spacing: 0.165rem;
    }
    .infoSectionMailButton {
        width: 19.5%;
    }
    .infoSectionImg {
        top: -0.75rem;
        right: -1.45rem;
        width: 10.4rem;
        height: 21.25rem;
        background-color: unset;
    }
    .infoSectionImgLink {
        width: 10.4rem;
        height: 23.8rem;
        border: none;
    }
    .infoSectionImgInside {
        width: 10.4rem;
        height: 23.8rem;
    }
    .infoSectionButton {
        width: 100%;
    }
    .main {
        width: auto;
        margin: 0 0.9rem 7.35rem;
        padding: 0;
    }
    .nav {
        flex-direction: column;
        margin: 0;
        padding: 0.25rem 2.85rem 3.25rem;
    }
    .navList {
        flex-direction: column;
        margin: 0 0 7.25rem;
    }
    .navItem {
        position: relative;
        display: grid;
        width: 100%;
        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            background-color: $white;
            opacity: 0.15;
        }
        &:nth-child(3) {
            width: 100%;
        }
        &:nth-child(4) {
            width: 100%;
        }
    }
    .navLink {
        margin: 0;
        padding: 2rem 0;
        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 1px;
            background-color: $white;
            opacity: 0.15;
        }
    }
    .navItemTrigger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 2rem 0;
    }
    .navItemTriggerSvg {
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        margin: 0 0.45rem 0 0;
    }
    .navItemTriggerSvgBorder {
        position: absolute;
        top: calc(50% - 1px);
    }
    .navItemTarget {
        margin: -1.5rem 0 0;
    }
    .navItemTargetLink {
        display: none;
        font-family: $gilroySemi;
        opacity: 0.5;
    }
    .navItemTargetList {
        padding: 0 0 1.85rem;
    }
    .navItemTargetItemMain {
        display: none;
    }
    .navChildList {
        margin: 0;
        border-top: 1px solid $white;
    }
    .navChildItemMain {
        .navChildItemLink {
            display: block;
            width: 100%;
        }
    }
    .navSubList {
        position: absolute;
        bottom: 10rem;
        left: 2.85rem;
    }
    .navSubItem {
        margin: 0 1.75rem 0 0;
    }
    .navSubItemText {
        font-size: 1.1rem;
        letter-spacing: 0.115rem;
    }
    .navBlankLinkSvg {
        top: 0.55rem;
        right: -0.75rem;
        width: 1.2rem;
        height: 1.2rem;
    }
    .navSns {
        margin: 0rem 0 0 -0.5rem;
    }
    .navSnsLink {
        padding: 0.55rem;
    }
    .navSubSmall {
        position: absolute;
        bottom: 4.45rem;
        right: 2.65rem;
        font-size: 1.1rem;
    }
    .logo {
        padding: 0 0 11rem;
    }
    .logoSvg {
        width: 30.1rem;
        height: 2.681rem;
    }
}
